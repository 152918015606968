html, body {
	overflow-x: hidden;
}
body {
	margin: 0;
	position: relative;
    width: 100%;
	background-color: #F7F7F7;
}

input, textarea {
	background-color: #F5F5F5;
}

@font-face {
	font-family: 'Terminal Grotesque';
	src: local('Terminal Grotesque'), url(./Assets/Fonts/terminal-grotesque.woff) format('woff');
}